<template>
  <dashboard-layout>
    <v-container v-if="patient">
      <v-row>
        <v-col>
          <text-button class="secondary--text" @click="setCurrentDate(prevDay)"
            ><v-icon class="mdi-rotate-225 mr-1">mdi-call-made</v-icon
            >{{ prevDay.format("DD.MM.YYYY") }}</text-button
          >
        </v-col>
        <v-spacer> </v-spacer>
        <v-col cols="auto">
          <text-button class="secondary--text" @click="setCurrentDate(nextDay)"
            >{{ nextDay.format("DD.MM.YYYY")
            }}<v-icon class="mdi-rotate-45 ml-1"
              >mdi-call-made</v-icon
            ></text-button
          >
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <TotalIntervalsLinear
            :intervals="intervals"
            :currentDate="currentDate"
            :patientIsOnNow="patient.is_on_now"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          ><IntervalsLinear :intervals="intervals" :currentDate="currentDate"
        /></v-col>
      </v-row>
      <v-row>
        <v-col><IntervalAddForm :currentDate="currentDate" @add="handleAddInterval" /></v-col>
      </v-row>

      <v-row>
        <v-col>
          <MobileTable v-if="isMobile" :headers="headers" :items="intervals" />
          <CustomTable v-else :headers="headers" :items="intervals"
        /></v-col>
      </v-row>
    </v-container>

    <delete-interval-modal
      @deleted="setIntervals"
      :interval="deletedInterval"
      @click:outside="showDeleteIntervalModal = false"
      v-if="showDeleteIntervalModal"
    />
    <edit-interval-modal
      @saved="handleSuccessfulSave"
      @click:outside="showEditIntervalModal = false"
      :interval="editedInterval"
      v-if="showEditIntervalModal"
    />
  </dashboard-layout>
</template>

<script>
import DashboardLayout from "@/layouts/dashboard/Index";
import Interval from "../../models/Interval";
import EditIntervalModal from "../../components/modals/EditIntervalModal";
import DeleteIntervalModal from "../../components/modals/DeleteIntervalModal";
import { mapActions, mapGetters } from "vuex";
import IntervalsLinear from "../../components/IntervalsLinear.vue";
import TotalIntervalsLinear from "../../components/TotalIntervalsLinear.vue";
import IntervalAddForm from "../../components/dashboard/IntervalAddForm.vue";
// import Timer from "easytimer.js";
export default {
  name: "dashboard-index",
  components: {
    DeleteIntervalModal,
    EditIntervalModal,
    DashboardLayout,
    IntervalsLinear,
    IntervalAddForm,
    TotalIntervalsLinear,
  },
  data() {
    return {
      latestInterval: {},
      secondsLeftToday: 0,
      left: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      seasons: ["Winter", "Spring", "Summer", "Fall"],
      newInterval: {},
      editedInterval: {},
      deletedInterval: {},
      showEditIntervalModal: false,
      showDeleteIntervalModal: false,
      showChart: false,
      currentDate: this.$date(),
    };
  },
  created() {
    this.setInit();
    this.headers = [
      {
        text: this.$t("scoliotracker.putOn"),
        value: "started_at",
        formatter: (v) => this.$date(v).format("H:mm:ss"),
      },
      {
        text: this.$t("scoliotracker.putOff"),
        value: "ended_at",
        formatter: (v) => this.$date(v).format("H:mm:ss"),
        default: "Сейчас",
      },
      {
        text: this.$t("scoliotracker.totalWorn"),
        value: "duration_for_humans",
        formatter: (v, obj) => (obj.ended_at ? v : "Сeйчас"),
      },
      {
        text: this.$t("scoliotracker.comment"),
        value: "note",
        formatter: (v) => v.substring(0, 25),
      },
    ];
  },
  computed: {
    prevDay() {
      return this.$date(this.currentDate).subtract(1, "days");
    },
    nextDay() {
      return this.$date(this.currentDate).add(1, "days");
    },
    ...mapGetters({
      intervals: "interval/items",
      patient: "patient/item",
    }),
  },
  methods: {
    ...mapActions({
      setIntervals: "interval/setItems",
      setPatient: "patient/setItem",
    }),
    setCurrentDate(date) {
      this.currentDate = date;
      this.setIntervals(new Date(date).toDateString());
    },
    async updateInterval() {
      let interval = new Interval(this.patient.latest_interval);
      await interval.save();
    },
    editInterval(i) {
      this.editedInterval = this.intervals[i];
      this.showEditIntervalModal = true;
    },
    deleteInterval(i) {
      this.deletedInterval = this.intervals[i];
      this.showDeleteIntervalModal = true;
    },
    async handleAddInterval(newInterval) {
      let interval = await new Interval(newInterval).save();

      this.currentDate = this.$date(interval.started_at);
      this.setIntervals(new Date(this.currentDate).toDateString());
    },
    createInterval() {
      this.showEditIntervalModal = true;
    },
    setInit() {
      this.setPatient();
      this.setIntervals(new Date().toDateString());
    }
  },
};
</script>

<style scoped>
